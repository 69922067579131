import Default from './organisms/default';
import Labels from './organisms/labels';
import MenuView from './menu-view';
import { useMemo } from 'react';
import { Chat } from '@prisma/client';

export enum MenuViews {
    DEFAULT,
    LABELS
}

const DefaultMenu = () => {
    return <MenuView title="Menú" content={<Default />} showBackButton={false} />;
};

const LabelMenu = ({ chat }: { chat?: Chat }) => {
    return (
        <MenuView
            title="Etiquetas"
            content={<Labels key={Date.now()} chat={chat} />}
            showBackButton={true}
        />
    );
};

interface MenuProps {
    currentView: MenuViews;
    params?: { chat?: Chat };
}

const Menu = ({ currentView, params }: MenuProps) => {
    const menuViewContent = useMemo(
        () => ({
            [MenuViews.DEFAULT]: <DefaultMenu />,
            [MenuViews.LABELS]: <LabelMenu chat={params?.chat} />
        }),
        [currentView, params?.chat] // Dependency array ensures recomputation when currentView changes
    );
    return menuViewContent[currentView];
};

export default Menu;
