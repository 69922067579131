import BasicButton, { BasicButtonType } from '@/app/components/molecules/basic-button';
import SearchView from '@/app/components/molecules/search-view';
import useUser from '@/app/contexts/userContext';
import ChatLabelList from '../molecules/chatlabel-list';
import { Chat, ChatLabel } from '@prisma/client';
import { useEffect, useState } from 'react';
import { cn } from '@/app/utils/cn';
import LabelConfiguration from '../molecules/label-configuration';
import { useMenuManager } from '@/app/contexts/menuManagerContext';
import { useGlobalContext } from '@/app/contexts/provider';
import { useLoadingHash } from '@/app/hooks/useLoadingHash';
import AbsoluteLoading from '@/app/components/molecules/loading-absolute';
import { useChatLabelStore } from '@/app/stores/useChatLabelStore';

interface LabelsProps {
    chat?: Chat;
}

const LOADING_HASH = 'updating';

const Labels = ({ chat }: LabelsProps) => {
    const { chatLabelService } = useGlobalContext();
    const { menuIsVisible } = useMenuManager();
    const { client, clientPlan } = useUser();
    const [selectedLabel, setSelectedLabel] = useState<ChatLabel>();
    const [showLabelConfiguration, setShowLabelConfiguration] = useState(false);
    const { isLoading, startLoading, stopLoading } = useLoadingHash(false);
    const [searchInput, setSearchInput] = useState('');
    const labelStore = useChatLabelStore();

    function searchListener(input: string) {
        setSearchInput(input);
    }

    function onUserWantsNewLabel() {
        setSelectedLabel(undefined);
        setShowLabelConfiguration(true);
    }

    async function onChatAssignment(label: ChatLabel) {
        if (!chat || isLoading) return;
        startLoading(LOADING_HASH);

        const response = await chatLabelService.addLabel(chat.id, label.id);
        if (response.error) return;

        stopLoading(LOADING_HASH);
        labelStore.assignLabel(chat.id, label.id);
    }

    async function onChatUnassignment(label: ChatLabel) {
        if (!chat || isLoading) return;
        startLoading(LOADING_HASH);
        const response = await chatLabelService.removeLabel(chat.id, label.id);
        if (response.error) return;

        stopLoading(LOADING_HASH);
        labelStore.unassignLabel(chat.id, label.id);
    }

    function onUserWantsToEditLabel(label: ChatLabel) {
        setSelectedLabel(label);
        setShowLabelConfiguration(true);
    }

    function onNewLabelCreation(newLabel: ChatLabel) {
        setShowLabelConfiguration(false);
        labelStore.addLabel(newLabel);
    }

    function onLabelDeletion(labelId: string) {
        setShowLabelConfiguration(false);
        labelStore.removeLabel(labelId);
    }

    function onLabelConfigurationClose() {
        setShowLabelConfiguration(false);
    }

    function onLabelEdition(editedLabel: ChatLabel) {
        setShowLabelConfiguration(false);
        labelStore.editLabel(editedLabel);
    }

    useEffect(() => {
        labelStore.setClientLabels(client?.chatLabels);
        return () => {
            setShowLabelConfiguration(false);
        };
    }, []);

    return (
        <div
            className={cn(
                'flex flex-col overflow-y-auto py-[10px] scrollbar-none',
                clientPlan.isFree() ? 'h-[calc(100vh-120px-66px)]' : 'h-[calc(100vh-80px-66px)]'
            )}
        >
            <SearchView
                listener={searchListener}
                placeholder="Buscar etiquetas..."
                classNames={{
                    parent: 'h-auto border border-gray-70 rounded-[4px] py-[16px] px-[21px]',
                    icon: 'collapse mr-[0px]',
                    input: 'text-gray-600'
                }}
            />

            <p className="text-gray-600 mt-[17px] mb-[14px]">Etiquetas</p>

            <ChatLabelList
                clientLabels={labelStore.filterClientLabels(searchInput)}
                onLabelEdit={onUserWantsToEditLabel}
                onChatAssignment={onChatAssignment}
                onChatUnassignment={onChatUnassignment}
                chatId={chat?.id}
            />

            <BasicButton
                styleType={BasicButtonType.GRAY}
                text="Crear una nueva etiqueta"
                className="py-[16px] mt-[13px]"
                onClick={onUserWantsNewLabel}
            />

            {showLabelConfiguration && menuIsVisible && (
                <LabelConfiguration
                    label={selectedLabel}
                    onClose={onLabelConfigurationClose}
                    onCreate={onNewLabelCreation}
                    onDelete={onLabelDeletion}
                    onEdit={onLabelEdition}
                />
            )}

            {isLoading && <AbsoluteLoading />}
        </div>
    );
};

export default Labels;
