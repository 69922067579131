import { X } from 'lucide-react';

export default function CloseButton({ ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <button
            className="absolute top-3 left-3 w-12 h-12 text-white bg-black bg-opacity-40 rounded-full flex items-center justify-center"
            {...rest}
        >
            <X size={24} />
        </button>
    );
}
