import React from 'react';

export default function CaptureButton({ ...rest }: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return (
        <div className="absolute bottom-5 inset-x-0 mx-auto w-20 h-20 bg-transparent border-[4px] border-white border-opacity-90 rounded-full flex items-center justify-center gap-6 animate-grow">
            <button
                className="w-[90%] h-[90%] bg-white bg-opacity-90 rounded-full"
                {...rest}
            ></button>
        </div>
    );
}
