import { Chat, ChatLabel } from '@prisma/client';
import { BaseResponse, HttpRequest } from './fetch-methods';
import { BaseService } from './base-service';
import generateUrl from './utils/url-generator';
import { ChatLabelUpdate } from '@/services/chatLabelService';

interface LabelBody extends Omit<ChatLabel, 'id'> {}
export interface ChatLabelService {
    createLabel(clientId: string, title: string, color: string): Promise<BaseResponse<ChatLabel>>;
    deleteLabel(clientId: string, labelId: string): Promise<BaseResponse<ChatLabel>>;
    addLabel(chatId: string, labelId: string): Promise<BaseResponse<Chat>>;
    removeLabel(chatId: string, labelId: string): Promise<BaseResponse<Chat>>;
    editLabel(
        clientId: string,
        labelId: string,
        update: ChatLabelUpdate
    ): Promise<BaseResponse<ChatLabel>>;
}

export class ChatLabelServiceImp extends BaseService implements ChatLabelService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Chat-Label-Service');
        this.httpRequest = httpRequest;
    }

    async addLabel(chatId: string, labelId: string): Promise<BaseResponse<Chat>> {
        const request = () =>
            this.httpRequest.post<Chat>({
                url: generateUrl(`api/chat/${chatId}/label`),
                body: { labelId }
            });
        return this.tryRequest<Chat>(request);
    }

    async removeLabel(chatId: string, labelId: string): Promise<BaseResponse<Chat>> {
        const request = () =>
            this.httpRequest.delete<Chat>({
                url: generateUrl(`api/chat/${chatId}/label`),
                body: { labelId }
            });
        return this.tryRequest<Chat>(request);
    }

    async createLabel(
        clientId: string,
        title: string,
        color: string
    ): Promise<BaseResponse<ChatLabel>> {
        const request = () =>
            this.httpRequest.post<ChatLabel>({
                url: generateUrl(`api/client/${clientId}/label`),
                body: { title, color }
            });
        return this.tryRequest<ChatLabel>(request);
    }

    async editLabel(
        clientId: string,
        labelId: string,
        update: ChatLabelUpdate
    ): Promise<BaseResponse<ChatLabel>> {
        const request = () =>
            this.httpRequest.patch<ChatLabel>({
                url: generateUrl(`api/client/${clientId}/label/${labelId}`),
                body: update
            });
        return this.tryRequest(request);
    }

    async deleteLabel(clientId: string, labelId: string): Promise<BaseResponse<ChatLabel>> {
        const request = () =>
            this.httpRequest.delete<ChatLabel>({
                url: generateUrl(`api/client/${clientId}/label/${labelId}`)
            });
        return this.tryRequest(request);
    }
}
