import { useState, useEffect } from 'react';

type UseDebouncedInputOptions = {
    delay?: number;
};

const useDelayInput = (
    listener: (value: string) => void,
    value?: string,
    { delay = 500 }: UseDebouncedInputOptions = {}
) => {
    const [inputValue, setInputValue] = useState(value);
    const [isFirstRender, setIsFirstRender] = useState(true); //to avoid to fetch on the first display.

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
            return;
        }

        const timer = setTimeout(() => {
            listener(inputValue || '');
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [inputValue]);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue((prev) => {
            if (prev === event.target.value) {
                return;
            }
            return event.target.value;
        });
    };

    const onClear = () => {
        setInputValue('');
    };

    return { inputValue, onInputChange, onClear };
};

export default useDelayInput;
