import { useState, useCallback } from 'react';

export interface VisibilityController {
    isVisible: boolean;
    show: () => void;
    hide: () => void;
    toggle: () => void;
}

export const useVisibilityController = (): VisibilityController => {
    const [isVisible, setIsVisible] = useState(false);

    const show = useCallback(() => setIsVisible(true), []);
    const hide = useCallback(() => setIsVisible(false), []);
    const toggle = useCallback(() => setIsVisible((prev) => !prev), []);

    return {
        isVisible,
        show,
        hide,
        toggle
    };
};
