import { MenuViews } from '..';
import MenuItem from '../atoms/menu-item';
import { useMenuManager } from '@/app/contexts/menuManagerContext';

export const Default = () => {
    const { showMenuView } = useMenuManager();

    return (
        <div className="flex flex-col w-full h-full gap-y-[10px]">
            <MenuItem
                iconName="label.svg"
                text="Etiquetas"
                onClick={() => {
                    showMenuView(MenuViews.LABELS);
                }}
            />
        </div>
    );
};

export default Default;
