import dynamic from 'next/dynamic';
import Offline from '@/app/components/organisms/modal/commons/offline';

// Import modals dynamically
export const dynamicModals = {
    commons: {
        alert: dynamic(() => import('./commons/alert'), {
            ssr: false
        }),
        confirmation: dynamic(() => import('./commons/confirmation'), {
            ssr: false
        }),
        delete: dynamic(() => import('./commons/delete'), {
            ssr: false
        }),
        offline: Offline // Must be imported statically to render it when the user is offline.
    }
};
