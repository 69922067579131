import { Prisma, ModelVersion, ClientPlan, $Enums, Namespace } from '@prisma/client';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { BaseService } from './base-service';
import {
    BarcodeScannerConfiguration,
    ContactCenterConfiguration,
    CTAConfiguration
} from '../pages/clients/client-form/utils/client-form-data-manager';

export type ChatConfigurationWithPlan = Prisma.ChatConfigurationGetPayload<{
    include: { presetMessages: true };
}> & { clientPlan?: ClientPlan };

type PostChatConfigBody = {
    model: ModelVersion;
    context?: string;
    organizationName?: string;
    businessDescription?: string;
    knowledgeBaseDescription?: string;
    botTaskDescription?: string;
    supportEmail?: string;
    botName?: string;
    statusText?: string;
    callToAction?: CTAConfiguration;
    inputPlaceholder?: string;
    answerTemperature?: number;
    questionTemperature?: number;
    showSourceDocs?: boolean;
    botIcon?: Blob | null;
    fabIcon?: Blob | null;
    contactCenterConfiguration?: ContactCenterConfiguration;
    productCardsConfiguration?: {
        enabled: boolean;
        errorIcon?: Blob | null;
        context?: string;
    };
    chatResetCommand?: string;
    autoOpenChat?: boolean;
    autoOpenChatDelay?: number;
    hasDataEnrichment?: boolean;
    barcodeScanner?: BarcodeScannerConfiguration;
    imageBasedSearch?: boolean;
};

export interface ChatConfigurationService {
    getConfiguration(
        identifier: string,
        getPlan?: boolean
    ): Promise<BaseResponse<ChatConfigurationWithPlan>>;
    postConfiguration(
        clientName: string,
        config: PostChatConfigBody
    ): Promise<BaseResponse<ChatConfigurationWithPlan>>;
    patchConfiguration(
        clientId: string,
        config: {
            model?: ModelVersion;
            context?: string;
            botName?: string;
            statusText?: string;
            callToAction?: CTAConfiguration;
            inputPlaceholder?: string;
            contactCenterDisabledText?: string;
            preOperatorMessage?: string;
            postOperatorMessage?: string;
            answerTemperature?: number;
            questionTemperature?: number;
            showSourceDocs?: boolean;
            botIcon?: Blob;
            fabIcon?: Blob;
            showableAtPosition?: number;
            contactCenterEnabled?: boolean;
            chatResetCommand?: string;
            barcodeScanner?: BarcodeScannerConfiguration;
        }
    ): Promise<BaseResponse<ChatConfigurationWithPlan>>;
    getFiles(clientId: string): Promise<BaseResponse<string[]>>;
}

export class ChatConfigurationImp extends BaseService implements ChatConfigurationService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Chat-Configuration-Service');
        this.httpRequest = httpRequest;
    }

    async getConfiguration(
        identifier: string,
        getPlan: boolean = false
    ): Promise<BaseResponse<ChatConfigurationWithPlan>> {
        const request = () =>
            this.httpRequest.get<ChatConfigurationWithPlan>({
                url: generateUrl(`api/chat/config`, {
                    identifier,
                    plan: getPlan
                })
            });

        return this.tryRequest<ChatConfigurationWithPlan>(request);
    }

    async patchConfiguration(
        clientId: string,
        config: {
            model?: $Enums.ModelVersion;
            context?: string;
            botName?: string;
            statusText?: string;
            callToAction?: CTAConfiguration;
            inputPlaceholder?: string;
            contactCenterDisabledText?: string;
            preOperatorMessage?: string;
            postOperatorMessage?: string;
            answerTemperature?: number;
            questionTemperature?: number;
            showSourceDocs?: boolean;
            botIcon?: Blob;
            fabIcon?: Blob;
            showableAtPosition?: number;
            contactCenterEnabled?: boolean;
            chatResetCommand?: string;
            barcodeScanner?: BarcodeScannerConfiguration;
        }
    ): Promise<BaseResponse<ChatConfigurationWithPlan>> {
        const request = () =>
            this.httpRequest.patch<ChatConfigurationWithPlan>({
                url: generateUrl(`api/client/${clientId}/chat/config`),
                body: config
            });

        return this.tryRequest<ChatConfigurationWithPlan>(request);
    }

    async postConfiguration(
        clientName: string,
        {
            model,
            context,
            organizationName,
            businessDescription,
            knowledgeBaseDescription,
            botTaskDescription,
            supportEmail,
            botName,
            statusText,
            callToAction,
            inputPlaceholder,
            answerTemperature,
            questionTemperature,
            showSourceDocs,
            botIcon,
            fabIcon,
            contactCenterConfiguration,
            productCardsConfiguration,
            chatResetCommand,
            autoOpenChat,
            autoOpenChatDelay,
            hasDataEnrichment,
            barcodeScanner,
            imageBasedSearch
        }: PostChatConfigBody
    ): Promise<BaseResponse<ChatConfigurationWithPlan>> {
        const form = new FormData();

        form.append('client', clientName);
        form.append('model', model);

        const appendIfDefined = (value: string | number | boolean | undefined, key: string) => {
            if (value !== undefined) form.append(key, value.toString());
        };

        appendIfDefined(callToAction?.enabled, 'callToActionEnabled');
        appendIfDefined(callToAction?.text, 'callToActionText');
        appendIfDefined(callToAction?.link, 'callToActionLink');
        appendIfDefined(context, 'context');
        appendIfDefined(organizationName, 'organizationName');
        appendIfDefined(businessDescription, 'businessDescription');
        appendIfDefined(knowledgeBaseDescription, 'knowledgeBaseDescription');
        appendIfDefined(botTaskDescription, 'botTaskDescription');
        appendIfDefined(supportEmail, 'supportEmail');
        appendIfDefined(botName, 'botName');
        appendIfDefined(statusText, 'statusText');
        appendIfDefined(inputPlaceholder, 'inputPlaceholder');
        appendIfDefined(productCardsConfiguration?.enabled, 'productCardsEnabled');
        appendIfDefined(productCardsConfiguration?.context, 'productCardsContext');
        if (productCardsConfiguration?.errorIcon !== undefined)
            productCardsConfiguration?.errorIcon
                ? form.append(
                      'productCardErrorIcon',
                      productCardsConfiguration?.errorIcon,
                      'productCardErrorIcon'
                  )
                : form.append('productCardErrorIcon', 'null');
        appendIfDefined(hasDataEnrichment, 'hasDataEnrichment');
        appendIfDefined(showSourceDocs, 'showSourceDocs');
        appendIfDefined(contactCenterConfiguration?.enabled, 'contactCenterEnabled');
        appendIfDefined(contactCenterConfiguration?.humanCommand, 'humanCommand');
        appendIfDefined(contactCenterConfiguration?.disabledText, 'contactCenterDisabledText');
        appendIfDefined(contactCenterConfiguration?.preOperatorMessage, 'preOperatorMessage');
        appendIfDefined(contactCenterConfiguration?.postOperatorMessage, 'postOperatorMessage');
        appendIfDefined(chatResetCommand, 'chatResetCommand');
        appendIfDefined(autoOpenChat, 'autoOpenChat');
        appendIfDefined(barcodeScanner?.enabled, 'barcodeScannerEnabled');
        appendIfDefined(imageBasedSearch, 'imageBasedSearch');

        if (
            typeof barcodeScanner?.startingPosition === 'number' &&
            barcodeScanner.startingPosition >= 0
        ) {
            form.append(
                'barcodeScannerStartingPosition',
                barcodeScanner.startingPosition.toString()
            );
        }

        if (
            typeof barcodeScanner?.characterCount === 'number' &&
            barcodeScanner.characterCount >= 0
        ) {
            form.append('barcodeScannerCharacterCount', barcodeScanner.characterCount.toString());
        }

        if (
            typeof answerTemperature === 'number' &&
            answerTemperature >= 0 &&
            answerTemperature <= 1
        )
            form.append('answerTemperature', answerTemperature.toString());
        if (
            typeof questionTemperature === 'number' &&
            questionTemperature >= 0 &&
            questionTemperature <= 1
        )
            form.append('questionTemperature', questionTemperature.toString());

        if (botIcon !== undefined)
            botIcon ? form.append('botIcon', botIcon, 'botIcon') : form.append('botIcon', 'null');
        if (fabIcon !== undefined)
            fabIcon ? form.append('fabIcon', fabIcon, 'fabIcon') : form.append('fabIcon', 'null');

        if (
            autoOpenChat &&
            typeof autoOpenChatDelay === 'number' &&
            autoOpenChatDelay >= 1 &&
            autoOpenChatDelay <= 120
        )
            form.append('autoOpenChatDelay', autoOpenChatDelay.toString());

        return await this.tryRequest(async () =>
            this.httpRequest.post({
                url: generateUrl(`api/chat/setup`),
                body: form
            })
        );
    }

    async getFiles(clientId: string): Promise<BaseResponse<string[]>> {
        return this.tryRequest(async () =>
            this.httpRequest.get({
                url: generateUrl(`api/client/${clientId}/chat/config/files`)
            })
        );
    }
}
