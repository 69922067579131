import { cn } from '@/app/utils/cn';
import { LabelColors } from '@/consts/label-colors';

interface ColorItemProps {
    color: string;
    isSelected: boolean;
    onSelected: (color: string) => void;
}

const ColorItem = ({ color, isSelected, onSelected }: ColorItemProps) => {
    return (
        <div
            className={cn(
                'w-full h-[42px] cursor-pointer rounded-[4px]',
                isSelected ? 'border-2 border-[#2D64DC] p-[2px]' : ''
            )}
            onClick={() => onSelected(color)}
        >
            <div className={cn('w-full h-full')} style={{ backgroundColor: color }} />
        </div>
    );
};

interface ColorSelectionProps {
    onSelectedColor: (color: string) => void;
    currentSelectedColor: string;
}

const ColorSelection = ({ onSelectedColor, currentSelectedColor }: ColorSelectionProps) => {
    return (
        <div className="grid grid-cols-5 gap-[8px]">
            {LabelColors.map((color, index) => (
                <ColorItem
                    key={index}
                    color={color}
                    onSelected={onSelectedColor}
                    isSelected={color === currentSelectedColor}
                />
            ))}
        </div>
    );
};

export default ColorSelection;
