import { VisibilityController, useVisibilityController } from '../../hooks/useVisibility';
import { createContext, useCallback, useContext, useState } from 'react';
import Menu, { MenuViews } from '../../components/organisms/menu';
import { Chat } from '@prisma/client';

export interface ShowViewParams {
    chat?: Chat;
}

export interface MenuController extends Omit<VisibilityController, 'show'> {
    showView: (view: MenuViews, params?: ShowViewParams) => void;
    currentView: MenuViews;
}

const MenuManagerContext = createContext<MenuController | undefined>(undefined);

export const MenuManagerProvider = ({ children }: { children: React.ReactNode }) => {
    const { isVisible, show, hide, toggle } = useVisibilityController();
    const [currentView, setCurrentView] = useState(MenuViews.DEFAULT);
    const [chat, setChat] = useState<Chat>();

    const showView = useCallback((view: MenuViews, params?: ShowViewParams) => {
        setCurrentView(view);
        setChat(params?.chat);
        show();
    }, []);

    return (
        <MenuManagerContext.Provider value={{ isVisible, showView, hide, toggle, currentView }}>
            {children}
            <Menu currentView={currentView} params={{ chat }} />
        </MenuManagerContext.Provider>
    );
};

export const useMenuManager = () => {
    const context = useContext(MenuManagerContext);

    if (context === undefined) throw Error('Out of context: MenuManagerProvider');

    return {
        showMenuView: (view: MenuViews, params?: ShowViewParams) => context.showView(view, params),
        menuIsVisible: context.isVisible,
        hideMenu: () => context.hide(),
        toggleMenu: () => context.toggle(),
        menuCurrentView: context.currentView
    };
};
