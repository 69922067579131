import useDelayInput from '@/app/hooks/useDelayInput';
import { cn } from '@/app/utils/cn';
import Image from 'next/image';

interface SearchViewProps {
    listener: (newInput: string) => void;
    placeholder?: string;
    value?: string;
    classNames?: { parent?: string; icon?: string; input?: string; clear?: string };
    iconSize?: number;
}

const SearchView = ({
    classNames,
    listener,
    placeholder,
    value,
    iconSize = 24
}: SearchViewProps) => {
    const { inputValue, onInputChange, onClear } = useDelayInput(listener, value, {
        delay: 500
    });

    return (
        <div
            className={cn(
                'h-full bg-white flex items-center px-[27px] rounded-[99px]',
                classNames?.parent
            )}
        >
            <Image
                height={iconSize}
                width={iconSize}
                src="/icons/search.svg"
                alt=""
                className={cn('mr-[26.5px]', classNames?.icon)}
            />
            <input
                type="text"
                id="inputText"
                placeholder={placeholder || 'Búsqueda'}
                value={inputValue}
                onChange={onInputChange}
                className={cn(
                    'outline-none w-full text-base placeholder-placeholder',
                    classNames?.input
                )}
            />
            {inputValue && (
                <Image
                    height={24}
                    width={24}
                    alt="Icono de una cruz X"
                    src="/icons/remove-circle.svg"
                    className={cn('select-none flex-grow-0 cursor-pointer', classNames?.clear)}
                    onClick={onClear}
                />
            )}
        </div>
    );
};

export default SearchView;
