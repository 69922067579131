import { useMenuManager } from '@/app/contexts/menuManagerContext';
import useUser from '@/app/contexts/userContext';
import { cn } from '@/app/utils/cn';
import { ReactNode } from 'react';
import Title from './atoms/title';
import Divider from './atoms/divider';
import { MenuViews } from '.';

interface MenuProps {
    showBackButton: boolean;
    title: string;
    content: ReactNode;
}

const MenuView = ({ showBackButton, title, content }: MenuProps) => {
    const { menuIsVisible, showMenuView, hideMenu } = useMenuManager();
    const { clientPlan } = useUser();

    return (
        <div
            className={cn(
                'flex flex-col fixed transform z-10 inset-y-0 right-0 w-[297px] h-full bg-white pl-[12px] pr-[18px]',
                menuIsVisible ? '-translate-x-0' : 'translate-x-full',
                clientPlan.isFree() ? 'mt-[120px]' : 'mt-[80px]',
                'transform transition-transform ease-in-out duration-300'
            )}
        >
            <div className="relative flex flex-col w-full">
                <Title
                    title={title}
                    onClosePressed={() => hideMenu()}
                    onBackPressed={
                        showBackButton
                            ? () => {
                                  showMenuView(MenuViews.DEFAULT);
                              }
                            : undefined
                    }
                />
                <Divider />
            </div>
            {content}
        </div>
    );
};

export default MenuView;
