import { cn } from '@/app/utils/cn';
import { Spinner } from '../atoms/spinner';

interface AbsoluteLoadingProps {
    classname?: string;
}

const AbsoluteLoading = ({ classname }: AbsoluteLoadingProps) => {
    return (
        <div
            className={cn(
                'absolute flex h-full w-full bg-background bg-opacity-60 items-center justify-center',
                classname
            )}
        >
            <Spinner size="large" />
        </div>
    );
};

export default AbsoluteLoading;
