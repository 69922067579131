interface LabelPreviewProps {
    title: string;
    color: string;
}

const LabelPreview = ({ title, color }: LabelPreviewProps) => {
    return (
        <div className="flex w-full h-auto py-[15px] justify-center items-center bg-[#F7F8F9]">
            <div
                className="flex items-center w-[329px] h-[42px] overflow-hidden rounded-[4px] px-[18px]"
                style={{ backgroundColor: color }}
            >
                <p className="font-[#1D1D1D] font-medium truncate" title={title}>
                    {title}
                </p>
            </div>
        </div>
    );
};

export default LabelPreview;
