import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { TwilioSenderResponse } from '@/services/twilio/models/responses';

export interface TwilioService {
    createSubAccount(
        phoneNumber: string,
        wabaId: string,
        clientId?: string
    ): Promise<BaseResponse<TwilioSenderResponse>>;
}

export class TwilioServiceImp implements TwilioService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        this.httpRequest = httpRequest;
    }

    async createSubAccount(
        phoneNumber: string,
        wabaId: string,
        clientId?: string
    ): Promise<BaseResponse<TwilioSenderResponse>> {
        return await this.httpRequest.post({
            url: generateUrl('api/whatsapp/signup'),
            body: { phoneNumber, wabaId, clientId }
        });
    }
}
