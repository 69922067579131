import { ChatLabel } from '@prisma/client';
import Image from 'next/image';

interface ChatLabelItemProps {
    label: ChatLabel;
    assignment?: {
        isSelected: boolean;
        onSelect: (label: ChatLabel) => void;
        onDeselect: (labe: ChatLabel) => void;
    };
    onEdit: (label: ChatLabel) => void;
}

const ChatLabelItem = ({ label, assignment, onEdit }: ChatLabelItemProps) => {
    return (
        <div className="flex h-[42px] w-full gap-x-[15px]" key={label.id}>
            {assignment && (
                <input
                    type="checkbox"
                    checked={assignment.isSelected}
                    onChange={(event) => {
                        if (event.target.checked) {
                            assignment.onSelect(label);
                        } else {
                            assignment.onDeselect(label);
                        }
                    }}
                />
            )}
            <div
                className="flex items-center w-full h-full overflow-hidden rounded-[4px] px-[18px]"
                style={{ backgroundColor: label.color }}
            >
                <p className="font-[#1D1D1D] font-medium truncate" title={label.title}>
                    {label.title}
                </p>
            </div>

            <Image
                src={'/icons/pencil.svg'}
                height={18}
                width={18}
                alt="pencil"
                className="hover:cursor-pointer"
                onClick={() => onEdit(label)}
            />
        </div>
    );
};

export default ChatLabelItem;
