import { AppProps } from 'next/app';
import { Poppins } from 'next/font/google';
import { useRouter } from 'next/router';

import { GlobalProvider } from '@/app/contexts/provider';
import { Routes, authPrefix } from '@/enums/routes.enum';
import Head from 'next/head';

import '../app/globals.css';
import Layout from '@/app/components/templates/layout';
import { ModalManagerProvider } from '@/app/contexts/modalManagerContext';
import GoogleAnalytics from '@/app/components/organisms/google-analytics';
import { CameraProvider } from '@/app/contexts/cameraProvider';

export const inter = Poppins({
    subsets: ['latin'],
    weight: ['300', '400', '500', '600', '700'],
    style: 'normal'
});

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();

    const hasLayout = (route: Routes) => {
        return (
            !route.includes(authPrefix) &&
            ![Routes.WEBCHAT, Routes.NOT_SUPPORTED, Routes.FASHION_CHAT].includes(route)
        );
    };

    const isWebchat = (route: Routes) => {
        return [Routes.WEBCHAT, Routes.FASHION_CHAT].includes(route);
    };

    return (
        <>
            <Head>
                <title>Delfi ™</title>
            </Head>
            <style jsx global>{`
                :root {
                    --font-family: ${inter.style.fontFamily};
                }
            `}</style>
            <GlobalProvider>
                <ModalManagerProvider>
                    <CameraProvider>
                        {hasLayout(router.pathname as Routes) ? (
                            <Layout
                                user={pageProps.user}
                                client={pageProps.client}
                                clientPlan={pageProps.clientPlan}
                            >
                                <Component {...pageProps} />
                            </Layout>
                        ) : (
                            <div>
                                {isWebchat(router.pathname as Routes) && (
                                    <GoogleAnalytics
                                        measurementId={
                                            process.env.NEXT_PUBLIC_ANALYTICS_MEASUREMENT_ID!
                                        }
                                    />
                                )}

                                <Component {...pageProps} />
                            </div>
                        )}
                    </CameraProvider>
                </ModalManagerProvider>
            </GlobalProvider>
        </>
    );
}

export default MyApp;
