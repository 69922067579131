import { cn } from '@/app/utils/cn';

interface DividerProps {
    classname?: string;
}

const Divider = ({ classname }: DividerProps) => {
    return <div className={cn('w-full h-[1px] bg-gray-D9', classname)} />;
};

export default Divider;
