import { useState } from 'react';

export enum LoadingHashCommonKeys {
    CLIENT = 'client',
    CONFIGURATION = 'configuration',
    USER = 'user',
    CLIENT_PLAN = 'clientPlan',
    MESSAGES = 'messages',
    CHAT = 'chat'
}

interface LoadingHashInterface {
    [key: string]: boolean;
}

//This hash exist to handle multiples request but you need to check when they are all done.
export class LoadingHash {
    private hash: LoadingHashInterface;
    constructor(private defaultLoading: boolean) {
        this.hash = {};
    }

    startLoading(key: string) {
        this.hash[key] = true;
        this.defaultLoading = false;
    }

    stopLoading(key: string) {
        if (!this.hash.hasOwnProperty(key)) {
            throw new Error('LoadingHash does not have key: ' + key);
        }

        this.hash[key] = false;
    }

    //The defaultLoading is needed to start showing the loading icon when you render the page and avoid undefined properties in the html.
    isLoading(): boolean {
        return this.defaultLoading || Object.keys(this.hash).some((key) => this.hash[key]);
    }

    setDefaultLoading(defaultLoading: boolean) {
        this.defaultLoading = defaultLoading;
    }
}

export const useLoadingHash = (defaultValue: boolean): LoadingHashMethods => {
    const [loadingHash, setLoadingHash] = useState(new LoadingHash(defaultValue));
    const [isLoading, setIsLoading] = useState(defaultValue);

    const startLoading = (key: string) => {
        loadingHash.startLoading(key);
        setIsLoading(loadingHash.isLoading());
    };

    const stopLoading = (key?: string) => {
        key ? loadingHash.stopLoading(key) : loadingHash.setDefaultLoading(false);
        setIsLoading(loadingHash.isLoading());
    };

    return { startLoading, stopLoading, isLoading };
};

export interface LoadingHashMethods {
    startLoading: (key: string) => void;
    stopLoading: (key?: string) => void;
    isLoading: boolean;
}
