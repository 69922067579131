import CaptureButton from '@/app/components/organisms/camera/atoms/capture-button';
import CloseButton from '@/app/components/organisms/camera/atoms/close-button';
import { useCameraContext } from '@/app/contexts/cameraProvider';
import InputButton from '@/app/pages/chat-bot/components/atoms/input-button';
import { cn } from '@/app/utils/cn';
import Image from 'next/image';
import { useState, useRef } from 'react';
import Webcam from 'react-webcam';

interface CameraProps {
    onPhotoSubmit: (imageSrc: string | null) => void;
}

const Camera: React.FC<CameraProps> = ({ onPhotoSubmit }) => {
    const [isCaptured, setIsCaptured] = useState<boolean>(false);
    const [isCameraReady, setIsCameraReady] = useState<boolean>(false);
    const webcamRef = useRef<Webcam | null>(null);
    const [imgSrc, setImgSrc] = useState<string | null>(null);

    const { isCameraVisible, hideCamera, videoConstraints } = useCameraContext();

    const capture = () => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) setImgSrc(imageSrc);
    };

    const retake = () => {
        setImgSrc(null);
        setIsCaptured(false);
    };

    const handleClose = () => {
        retake();
        hideCamera();
    };

    if (!isCameraVisible) return null;

    return (
        <div
            className={cn(
                'fixed inset-0 z-[10000] w-[100svw] h-[100svh] animate-grow bg-black',
                !isCameraReady && 'hidden'
            )}
        >
            {!isCaptured && (
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className="w-full h-full object-cover"
                    videoConstraints={videoConstraints}
                    onUserMedia={() => setIsCameraReady(true)}
                    screenshotQuality={1}
                    forceScreenshotSourceSize
                />
            )}

            {imgSrc && (
                <>
                    <Image
                        src={imgSrc || ''}
                        fill
                        onLoad={() => setIsCaptured(true)}
                        alt="Imagen tomada del usuario"
                        className="w-full h-full object-cover"
                    />

                    <InputButton
                        type="button"
                        className="absolute bottom-5 right-5 w-12 h-12 rounded-full"
                        onClick={() => {
                            handleClose();
                            onPhotoSubmit(imgSrc);
                        }}
                    />
                </>
            )}

            <CloseButton onClick={isCaptured ? retake : handleClose} />

            {!isCaptured && <CaptureButton onClick={capture} />}
        </div>
    );
};

export default Camera;
