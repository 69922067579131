import LoadingDots from '@/app/components/atoms/loading-dots';
import { cn } from '@/app/utils/cn';
import Image from 'next/image';

interface InputButtonProp extends React.ComponentPropsWithoutRef<'button'> {
    loading?: boolean;
}

const InputButton = ({ loading = false, ...rest }: InputButtonProp) => {
    return (
        <button
            type="submit"
            disabled={loading}
            {...rest}
            className={cn(
                'h-full aspect-square bg-[#6E6EFF] rounded-[6px] flex items-center justify-center p-2',
                'hover:opacity-90',
                rest.className
            )}
        >
            {loading ? (
                <LoadingDots color="#fff" />
            ) : (
                <div className="relative h-full aspect-square">
                    <Image src="/icons/send.svg" alt="" fill sizes="32px" />
                </div>
            )}
        </button>
    );
};

export default InputButton;
