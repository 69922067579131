import { cn } from '@/app/utils/cn';
import Image from 'next/image';

interface TitleProps {
    title: string;
    onClosePressed: () => void;
    onBackPressed?: () => void;
    classname?: string;
}

const Title = ({ title, onClosePressed, onBackPressed, classname }: TitleProps) => {
    return (
        <div className={cn('flex items-center justify-between w-full h-[66px]', classname)}>
            <div className="h-[24px] w-[24px]">
                {onBackPressed && (
                    <Image
                        src={'/icons/arrow-backwards-black.svg'}
                        height={24}
                        width={24}
                        alt="back"
                        className="hover:cursor-pointer"
                        onClick={onBackPressed}
                    />
                )}
            </div>

            <p>{title}</p>

            <Image
                src={'/icons/close-black.svg'}
                height={24}
                width={24}
                alt="close"
                className="hover:cursor-pointer"
                onClick={onClosePressed}
            />
        </div>
    );
};

export default Title;
