import { cn } from '@/app/utils/cn';
import Image from 'next/image';

interface MenuItemProps {
    iconName: string;
    text: string;
    onClick: () => void;
}

const MenuItem = ({ iconName, text, onClick }: MenuItemProps) => {
    return (
        <div
            className={cn('flex w-full justify-start py-[5px] gap-x-[9px]', 'hover:cursor-pointer')}
            onClick={onClick}
        >
            <Image width={20} height={20} src={`/icons/${iconName}`} alt={iconName} />
            <p>{text}</p>
        </div>
    );
};

export default MenuItem;
