import { create } from 'zustand';
import { ChatLabel } from '@prisma/client';

interface LabelStore {
    clientLabels: ChatLabel[];
    setClientLabels: (labels?: ChatLabel[]) => void;
    addLabel: (label: ChatLabel) => void;
    removeLabel: (labelId: string) => void;
    editLabel: (updatedLabel: ChatLabel) => void;
    filterClientLabels: (input: string) => ChatLabel[];

    chatLabelsId: Record<string, string[]>; // Un diccionario de <chatId, susLablesAsignados>
    setChatLabels: (chatId: string, labels: string[]) => void;
    assignLabel: (chatId: string, labelId: string) => void;
    unassignLabel: (chatId: string, labelId: string) => void;
    getAssignedLabels: (chatId: string) => ChatLabel[];
    hasLabels: (chatId: string) => boolean;

    clearAll: () => void;
}

export const useChatLabelStore = create<LabelStore>((set) => ({
    clientLabels: [],
    chatLabelsId: {},

    setClientLabels: (newLabels) =>
        set((state) => (state.clientLabels.length === 0 ? { clientLabels: newLabels || [] } : {})),

    addLabel: (label) => set((state) => ({ clientLabels: [...state.clientLabels, label] })),

    //TODO; remove from the chats labels too.
    removeLabel: (labelId) =>
        set((state) => ({
            clientLabels: state.clientLabels.filter((label) => label.id !== labelId)
        })),

    editLabel: (updatedLabel) =>
        set((state) => ({
            clientLabels: state.clientLabels.map((label) =>
                label.id === updatedLabel.id ? updatedLabel : label
            )
        })),

    filterClientLabels: (input): ChatLabel[] =>
        useChatLabelStore
            .getState()
            .clientLabels.filter((label: ChatLabel) =>
                label.title.toLowerCase().includes(input.toLowerCase())
            ),

    setChatLabels: (chatId, labels) => {
        set((state) =>
            state.chatLabelsId[chatId]
                ? {}
                : { chatLabelsId: { ...state.chatLabelsId, [chatId]: labels } }
        );
    },

    assignLabel: (chatId, label) =>
        set((state) => ({
            chatLabelsId: {
                ...state.chatLabelsId,
                [chatId]: state.chatLabelsId[chatId]
                    ? [...state.chatLabelsId[chatId], label]
                    : [label]
            }
        })),

    unassignLabel: (chatId, labelId) =>
        set((state) => ({
            chatLabelsId: {
                ...state.chatLabelsId,
                [chatId]:
                    state.chatLabelsId[chatId]?.filter((chatLabelId) => chatLabelId !== labelId) ||
                    []
            }
        })),

    getAssignedLabels: (chatId): ChatLabel[] => {
        const state: LabelStore = useChatLabelStore.getState();
        return (state.chatLabelsId[chatId] || [])
            .map((labelId) => state.clientLabels.find((label) => label.id === labelId))
            .filter(Boolean) as ChatLabel[];
    },

    hasLabels: (chatId): boolean => {
        return (useChatLabelStore.getState().chatLabelsId[chatId] || []).length > 0;
    },

    clearAll: () =>
        set((state) => ({
            chatLabelsId: {},
            clientLabels: []
        }))
}));
