import { ChatLabel } from '@prisma/client';
import ChatLabelItem from '../atoms/chatlabel-item';
import { useChatLabelStore } from '@/app/stores/useChatLabelStore';

interface ChatLabelListProps {
    clientLabels: ChatLabel[];
    onChatAssignment: (label: ChatLabel) => void;
    onChatUnassignment: (label: ChatLabel) => void;
    onLabelEdit: (label: ChatLabel) => void;
    chatId?: string;
}

const ChatLabelList = ({
    clientLabels,
    onChatAssignment,
    onChatUnassignment,
    onLabelEdit,
    chatId
}: ChatLabelListProps) => {
    const labelStore = useChatLabelStore();

    return (
        <div className="flex flex-col h-fit gap-y-[5px]">
            {clientLabels.map((clientLabel) => {
                return (
                    <ChatLabelItem
                        label={clientLabel}
                        key={clientLabel.id}
                        assignment={
                            chatId
                                ? {
                                      isSelected: labelStore.chatLabelsId[chatId].some(
                                          (labelId) => {
                                              return labelId === clientLabel.id;
                                          }
                                      ),
                                      onSelect: () => {
                                          onChatAssignment(clientLabel);
                                      },
                                      onDeselect: () => {
                                          onChatUnassignment(clientLabel);
                                      }
                                  }
                                : undefined
                        }
                        onEdit={onLabelEdit}
                    />
                );
            })}
        </div>
    );
};

export default ChatLabelList;
