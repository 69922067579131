import { useContext, createContext, useState, ReactNode } from 'react';
import { useVisibilityController } from '@/app/hooks/useVisibility';
import Camera from '@/app/components/organisms/camera';

export interface CameraContext {
    isCameraVisible: boolean;
    showCamera: () => void;
    hideCamera: () => void;
    toggleCamera: () => void;
    videoConstraints: MediaTrackConstraints;
    photo: string | null;
}

const CameraContext = createContext<CameraContext | undefined>(undefined);

export function CameraProvider({ children }: { children: ReactNode }) {
    const [photo, setPhoto] = useState<string | null>(null);
    const [videoConstraints, setVideoConstraints] = useState<MediaTrackConstraints>({
        facingMode: 'environment',
        width: { ideal: 1920 },
        height: { ideal: 1080 }
    });

    const {
        isVisible: isCameraVisible,
        show: showCamera,
        hide: hideCamera,
        toggle: toggleCamera
    } = useVisibilityController();

    return (
        <CameraContext.Provider
            value={{
                isCameraVisible,
                hideCamera,
                showCamera,
                toggleCamera,
                videoConstraints,
                photo
            }}
        >
            {children}
            <Camera
                onPhotoSubmit={(photo: string | null) => {
                    if (photo) setPhoto(photo);
                }}
            />
        </CameraContext.Provider>
    );
}

export const useCameraContext = (): CameraContext => {
    const context = useContext(CameraContext);

    if (context === undefined) {
        throw new Error('useCameraContext must be used within a CameraProvider');
    }

    return context;
};
