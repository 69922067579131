export const LabelColors = [
    '#C6F1DC',
    '#F5E7A8',
    '#F9DFCB',
    '#F8D7D3',
    '#DED8FA',
    '#72CB9B',
    '#EFCE60',
    '#F1A76E',
    '#E7796D',
    '#9C90E9',
    '#41825D',
    '#8E7023',
    '#B45821',
    '#B94335',
    '#6B5EC0',
    '#D0DFFC',
    '#CEECF9',
    '#D9F0AF',
    '#F6D2EA',
    '#DDDFE3',
    '#689BF8',
    '#81C1DD',
    '#9FC65B',
    '#D87AB8',
    '#8790A0',
    '#2D64DC',
    '#3F7B98',
    '#637E33',
    '#A24D84',
    '#646F84'
];

export function getRandomColor(): string {
    return LabelColors[Math.floor(Math.random() * LabelColors.length)];
}
